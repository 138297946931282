import { createContext, Dispatch, SetStateAction } from "react";
import { AppConfig } from "../react-app-env";

interface AppConfigContextType {
  ready: boolean;
  config: AppConfig;
  setReady: Dispatch<SetStateAction<boolean>>;
  setConfig: Dispatch<SetStateAction<AppConfig>>;
}

const AppConfigContext = createContext<AppConfigContextType>(
  {} as AppConfigContextType
);

export default AppConfigContext;
