import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.svg";

const Footer: React.FC = () => {
  const { t } = useTranslation<string>();

  return (
    <footer className="flex flex-row px-2 py-4 items-center text-xs">
      <a
        href="https://www.un.org/peacebuilding/fund"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="pr-4 h-16"
          alt={t("UN Secretary-General’s Peacebuilding Fund")}
          src={Logo}
        />
      </a>
      <div>
        <div>
          {t("footer.initiated_by")}
        </div>
        <Link
          className="text-gray-500 underline cursor-pointer"
          role="link"
          to="/about"
          tabIndex={0}
        >
          {t("footer.about")}
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
