import React, { useEffect, useRef } from "react";
import "./Modal.css";

const Modal: React.FC<{ onClickOutside: () => void }> = ({
  onClickOutside,
  children,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const clickListener = (e: MouseEvent) => {
      if (overlayRef?.current?.contains(e.target as Node)) {
        onClickOutside();
      }
    };
    document.body.addEventListener("click", clickListener);

    const keyboardListener = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClickOutside();
      }
    };
    document.addEventListener("keydown", keyboardListener);

    return () => {
      document.body.removeEventListener("click", clickListener);
      document.removeEventListener("keydown", keyboardListener);
    };
  }, [onClickOutside]);

  return (
    <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
      <div
        id="modal-overlay"
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50 cursor-pointer"
        ref={overlayRef}
      />
      <div className="modal-container bg-white w-11/12 md:max-w-xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-4 text-left px-6">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
