import { faDownload, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";
import AppConfigContext from "../../context/AppConfigContext";
import FilterStatesContext from "../../context/FilterStatesContext";
import OrganisationsContext from "../../context/OrganisationsContext";
import { stringify } from "../../csv";
import { Organisation } from "../../react-app-env";
import { filterOrganisationsByCoordinates } from "../map/util";
import OrgListItem from "../orglistitem/OrgListItem";
import { ReactComponent as LeftPointingChevrons } from "../../assets/left-pointing-chevrons.svg";

const PAGE_SIZE = 50;

const OrgList: React.FC<{
  hoveredOrganisation: Organisation | null | undefined;
  setOrgListOpen: Dispatch<SetStateAction<boolean>>;
  currency: string;
}> = ({ hoveredOrganisation, setOrgListOpen, currency }) => {
  const {
    config: { organisationFieldNames },
  } = useContext(AppConfigContext);
  const { organisations, loading } = useContext(OrganisationsContext);
  const { locationFilter } = useContext(FilterStatesContext);
  const { t } = useTranslation<string>();
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [copied, setCopied] = useState(false);

  // Reset pagination when the organisations change
  useEffect(() => {
    setLimit(PAGE_SIZE);
  }, [organisations]);

  organisations.sort((orgA, orgB) => {
    const cleanName = (name: string) =>
      name.replaceAll('"', '').replaceAll('(', '').replaceAll('/', '').trim();

    const cleanOrgAName = cleanName(orgA.name);
    const cleanOrgBName = cleanName(orgB.name);

    return cleanOrgAName.localeCompare(cleanOrgBName);
  });

  const onClickCopy = () => {
    const textField = document.createElement("textarea");
    textField.innerText = window.location.href;
    document.body.appendChild(textField);
    if (window.navigator.platform === "iPhone") {
      textField.setSelectionRange(0, 99999);
    } else {
      textField.select();
    }
    document.execCommand("copy");
    textField.remove();
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  // Only show organisations that are currently visible on the map
  // This filter isn't done globally because the map needs all the data to render
  // the heatmap properly
  const visibleOrganisations = locationFilter
    ? filterOrganisationsByCoordinates(
        organisations,
        locationFilter,
        organisationFieldNames.coordinates
      )
    : organisations;

  return loading ? (
    <div>
      <p>{t("loading")}</p>
    </div>
  ) : (
    <div className="px-2">
      <div className="flex justify-between items-start">
        <h1 className="p-2">
          <span className="text-3xl font-medium">
            {visibleOrganisations.length}{" "}
          </span>
          <span className="text-gray-500 font-medium">
            {t("organisations.peacebuilding", {
              count: visibleOrganisations.length,
            })}
          </span>
        </h1>
        <button
          type="button"
          onClick={() => setOrgListOpen(false)}
          className="p-2"
        >
          <LeftPointingChevrons className="chevron hidden md:block" />
        </button>
      </div>
      <div className="flex mb-2">
        <a
          className="pill mr-1"
          download="pbfsahel.csv"
          href={`data:text/csv;charset=utf-8,${encodeURIComponent(
            stringify(visibleOrganisations.map((o) => o.data))
          )}`}
        >
          <FontAwesomeIcon icon={faDownload} className="inline-block mr-1" />
          {t("organisations.download")}
        </a>
        <button className="pill" type="button" onClick={onClickCopy}>
          <FontAwesomeIcon icon={faLink} className="inline-block mr-1" />
          {t(copied ? "organisations.copied" : "organisations.link")}
        </button>
      </div>
      <ul>
        {visibleOrganisations.slice(0, limit).map((org) => (
          <li key={org.id}>
            <OrgListItem
              org={org}
              highlight={org?.id === hoveredOrganisation?.id}
              currency={currency}
            />
          </li>
        ))}
      </ul>
      {limit < visibleOrganisations.length ? (
        <div className="text-center">
          <button
            className="w-full text-white bg-peaceblue p-4 my-8 rounded text-sm"
            type="button"
            onClick={() => setLimit(limit + PAGE_SIZE)}
          >
            {t("organisations.load_more")}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default OrgList;
