import getUrls from "get-urls";
import { LanguageMap, Organisation } from "./react-app-env";

export const slugify = (str: string): string =>
  str.replace(/\s/g, "_").replace(/\//g, "|");

export const titleCase = (str: string): string =>
  str
    .split(/\s+/)
    .map(
      (s) =>
        `${s.substring(0, 1).toLocaleUpperCase()}${s
          .substring(1)
          .toLocaleLowerCase()}`
    )
    .join(" ");

export const getTranslation = (
  content: string | LanguageMap,
  language: string
): string => {
  if (typeof content === "string") {
    return content;
  }
  return content[language] || Object.values(content)[0];
};

export const extractEmailAddress = (data: Organisation["data"]): string =>
  data["Adresse électronique :"];

export const extractWebAddress = (
  data: Organisation["data"]
): string | null => {
  const rawWebAddress =
    data["Site web de l'initiative, page facebook ou autre"];

  if (rawWebAddress !== "") {
    const webAddresses = Array.from(getUrls(rawWebAddress));
    const webAddressWithoutPlatforms = webAddresses.filter((address) => {
      return (
        address.includes("youtube") === false &&
        address.includes("facebook") === false &&
        address.includes("twitter") === false
      );
    });

    return webAddressWithoutPlatforms[0] ? webAddresses[0] : null;
  }

  return null;
};

export const extractFocuses = (data: Organisation["data"]): string[] => {
  const mainActivities = data["Domaine d'activité principal"];

  return mainActivities
    .split(";")
    .map((e) => e.split(","))
    .reduce((acc, cur) => cur.concat(acc), [])
    .filter((e) => !!e)
    .map((s) => titleCase(s));
};
