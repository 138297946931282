import React, { useState } from "react";
import FilterStatesContext from "../context/FilterStatesContext";
import { Bounds, FilterStates } from "../react-app-env";

const saveFilters = (filters: FilterStates) => {
  const query = [];
  for (const filter of filters) {
    const fieldName = encodeURIComponent(filter.fieldName);
    for (const value of filter.values) {
      query.push(`${fieldName}[]=${encodeURIComponent(value)}`);
    }
  }
  const url = `${window.location.pathname}?${query.join("&")}`;
  window.history.pushState(null, "", url);
};

const loadFilters = () => {
  let { search } = window.location;
  const filters: FilterStates = [];
  if (!search) {
    return filters;
  }
  search = search.substring(1); // remove '?'
  const params = search.split("&");
  for (const param of params) {
    // eslint-disable-next-line prefer-const
    let [fieldName, value] = param.split("=");
    if (fieldName.endsWith("[]")) {
      fieldName = fieldName.substring(0, fieldName.length - 2);
    }
    fieldName = decodeURIComponent(fieldName);
    let filter = filters.find((f) => f.fieldName === fieldName);
    if (!filter) {
      filter = {
        fieldName,
        values: [],
      };
      filters.push(filter);
    }
    filter.values.push(decodeURIComponent(value));
  }
  return filters;
};

const FilterStatesProvider: React.FC = ({ children }) => {
  const [filters, setFilters] = useState<FilterStates>(loadFilters());
  const [locationFilter, setLocationFilter] = useState<Bounds | null>(null);

  // Function for updating the values of a filter, used by components.
  // Causes OrganisationsProvider to reload data.
  const setFilterState = (fieldName: string, values: string[]) => {
    const nextFilters = [...filters];
    let filter = filters.find((f) => f.fieldName === fieldName);

    if (!filter) {
      filter = { fieldName, values: [] };
      nextFilters.push(filter);
    }

    filter.values = values;

    saveFilters(nextFilters);
    setFilters(nextFilters);

    // Reset location filter when the main filters change
    setLocationFilter(null);
  };

  const clearAllFilters = () => {
    setFilters([]);
  };

  return (
    <FilterStatesContext.Provider
      value={{
        filterStates: filters,
        setFilterState,
        clearAllFilters,
        locationFilter,
        setLocationFilter,
      }}
    >
      {children}
    </FilterStatesContext.Provider>
  );
};

export default FilterStatesProvider;
