import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ReactComponent as RightPointingChevrons } from "../../assets/right-pointing-chevrons.svg";
import { MOBILE_BREAKPOINT } from "../../constants";
import AppConfigContext from "../../context/AppConfigContext";
import FilterStatesContext from "../../context/FilterStatesContext";
import useLanguage from "../../hooks/useLanguage";
import { Organisation } from "../../react-app-env";
import About from "../about/About";
import Reports from "../reports/Reports";
import Filters from "../filters/Filters";
import Footer from "../footer/Footer";
import LoadingOverlay from "../loadingoverlay/LoadingOverlay";
import Map from "../map/Map";
import MobileControls from "../mobilecontrols/MobileControls";
import Navbar from "../navbar/Navbar";
import IntroText from "../navbar/IntroText";
import OrgList from "../orglist/OrgList";
import OrgView from "../orgview/OrgView";

// This component handles the layout, but look in index.tsx for data management
const App: React.FC = () => {
  const language = useLanguage();
  const [currency, setCurrency] = useState("USD");
  const { t, i18n } = useTranslation<string>();
  const { ready } = useContext(AppConfigContext);
  const [isMobile, setMobile] = useState(window.innerWidth < MOBILE_BREAKPOINT);
  const [filtersHidden, setFiltersHidden] = useState(isMobile);
  const [mapHidden, setMapHidden] = useState(isMobile);
  const { clearAllFilters } = useContext(FilterStatesContext);

  useEffect(() => {
    const resizeListener = () => {
      const isNowMobile = window.innerWidth < MOBILE_BREAKPOINT;
      setMobile(isNowMobile);
      setFiltersHidden(isNowMobile);
      setMapHidden(isNowMobile);
    };
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, []);

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [orgListOpen, setOrgListOpen] = useState(true);
  const [viewMode, setViewMode] = useState("points");

  const [
    hoveredOrganisation,
    setHoveredOrganisation,
  ] = useState<Organisation | null>();

  const clearFilters = () => {
    clearAllFilters();
  };

  const closedLeftPanel = (
    <div className="flex flex-col bg-peaceblue align-center p-4 md:max-h-screen">
      <button
        type="button"
        onClick={() => {
          setSidebarOpen(true);
        }}
      >
        <RightPointingChevrons className="chevron hidden md:block" />
      </button>
    </div>
  );

  const openLeftPanel = (
    <div className="flex flex-col md:w-1/5 justify-between md:border-r-2 md:h-full md:max-h-screen overflow-y-scroll md:overflow-auto">
      <div>
        <Navbar setSidebarOpen={setSidebarOpen} />
        <IntroText />
        <MobileControls
          filtersHidden={filtersHidden}
          mapHidden={mapHidden}
          onToggleFilters={() => setFiltersHidden(!filtersHidden)}
          onToggleMap={() => setMapHidden(!mapHidden)}
        />
        {filtersHidden ? null : <Filters />}
        <div className="p-2">
          <button
            className="text-gray-500 underline text-xs"
            type="button"
            onClick={() => clearFilters()}
          >
            {t("clear_filters")}
          </button>
        </div>
      </div>
      {!isMobile && <Footer />}
    </div>
  );

  const orgListColumnClasses = classNames(
    "flex-1 md:block md:border-r-2 overflow-auto p-2 bg-gray-100 md:max-h-screen md:overflow-y-scroll",
    mapHidden ? "block" : "hidden"
  );

  const openOrgList = (
    <div className={orgListColumnClasses}>
      <OrgList
        hoveredOrganisation={hoveredOrganisation}
        setOrgListOpen={setOrgListOpen}
        currency={currency}
      />
      {isMobile && <Footer />}
    </div>
  );

  const closedOrgList = (
    <div className="flex flex-col align-center bg-gray-100 p-4 md:max-h-screen">
      <button
        type="button"
        onClick={() => {
          setOrgListOpen(true);
        }}
        className="py-tiny"
      >
        <RightPointingChevrons className="chevron hidden md:block" />
      </button>
    </div>
  );

  const panelContainerClasses = classNames(
    "flex flex-col min-h-screen md:min-h-0 md:h-full",
    sidebarOpen ? "md:flex md:flex-row" : "md:flex md:flex-row"
  );

  const mapColumnClasses = classNames(
    "flex flex-col md:max-h-screen md:overflow-y-scroll",
    mapHidden ? "invisible" : "visible",
    sidebarOpen ? "flex-grow" : "flex-2"
  );

  const pointButtonClasses = classNames(
    "border p-3 border-solid border-gray-400 rounded-l",
    viewMode === "points"
      ? "bg-gray-200 font-medium"
      : "bg-white font-extralight"
  );

  const heatmapButtonClasses = classNames(
    "border p-3 border-l-0 border-solid border-gray-400 rounded-r",
    viewMode === "heatmap"
      ? "bg-gray-200 font-medium"
      : "bg-white font-extralight"
  );

  return (
    <>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>
      {ready ? (
        <Router>
          <div className={panelContainerClasses}>
            {sidebarOpen ? openLeftPanel : closedLeftPanel}
            <div className="static md:fixed top-1 right-1 p-1 z-20">
              <select
                className="pill mr-4"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option value="USD">USD</option>
                <option value="FCFA">FCFA</option>
              </select>
              <select
                className="pill"
                value={language}
                onChange={(e) => i18n.changeLanguage(e.target.value)}
              >
                <option value="fr">Français</option>
                <option value="en">English</option>
              </select>
            </div>
            {orgListOpen ? openOrgList : closedOrgList}
            <div className={mapColumnClasses}>
              <div className="m-2 absolute z-10">
                <button
                  onClick={() => setViewMode("points")}
                  type="button"
                  className={pointButtonClasses}
                >
                  {t("map.points")}
                </button>
                <button
                  onClick={() => setViewMode("heatmap")}
                  type="button"
                  className={heatmapButtonClasses}
                >
                  {t("map.heatmap")}
                </button>
              </div>
              <Map
                autofocus={!isMobile}
                sidebarOpen={sidebarOpen}
                orgListOpen={orgListOpen}
                hoveredOrganisation={hoveredOrganisation}
                setHoveredOrganisation={setHoveredOrganisation}
                viewMode={viewMode}
              />
            </div>
          </div>
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/reports">
              <Reports />
            </Route>
            <Route path="/:slug">
              <OrgView currency={currency} />
            </Route>
            <Route path="/" />
          </Switch>
        </Router>
      ) : (
        <LoadingOverlay />
      )}
    </>
  );
};

export default App;
