import { Dict } from "./react-app-env";

const escapeQuotes = (str: string): string => {
  return `"${str.replace(/"/, '"')}"`;
};

// eslint-disable-next-line import/prefer-default-export
export const stringify = (dicts: Dict[]): string => {
  if (dicts.length < 1) {
    return "";
  }
  const columns = Object.keys(dicts[0]);
  const rows = [columns.map(escapeQuotes).join(",")];
  for (const dict of dicts) {
    const row = columns.map((c) => escapeQuotes(dict[c])).join(",");
    rows.push(row);
  }
  return rows.join("\n");
};
