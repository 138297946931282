import axios from "axios";
import {
  API_URL,
  FALSE_CONSTANT,
  ORGANISATION_FOCUS_PROPERTY,
  TRUE_CONSTANT,
} from "../constants";
import i18n from "../i18n";
import {
  AppConfig,
  Dict,
  DisplayItem,
  FilterConfig,
  FilterStates,
  LanguageMap,
  Option,
  Organisation,
} from "../react-app-env";
import { slugify, titleCase } from "../util";

// const client = axios.create({ baseURL: API_URL });
const client = axios.create();
const apiURL = API_URL;

interface PartialFilterConfig {
  type: string;
  labels: LanguageMap;
  fieldName: string;
  options: (string | number)[];
}

interface AppDataResponse {
  display_config: {
    filters: PartialFilterConfig[];
    summary: DisplayItem[];
    profile: DisplayItem[];
    name_fieldname: string;
    country_fieldname: string;
    coords_fieldname: string;
    jeunes_fieldname: string;
    femmes_fieldname: string;
    border_base_fieldname: string;
    registered_fieldname: string;
    size_fieldname: string;
    budget_fieldname: string;
  };
  data: {
    data_id: string;
    versions: { data: Dict }[];
  }[];
}

interface AppData {
  config: AppConfig;
  organisations: Organisation[];
}

export default class API {
  // Always access through API.loadAppData() to ensure data is available
  static appData: AppData;

  static async loadAppData(refreshAppData = false): Promise<AppData> {
    if (!API.appData || refreshAppData) {
      try {
        const response = await client.get(API_URL || "/mapping_project.json", {
          onDownloadProgress: (progressEvent) => {
            const progressStatus = document.querySelector('#progress') || Object();
            if (progressStatus !== Object()) {
              // Temporarily hardcoding the expected size of data
              progressStatus.textContent = `Loaded: ${Math.round(((progressEvent.loaded) / 4300000) * 100)}%`;
            }
          }
        });
        const appData: AppDataResponse = response.data;

        const config: AppConfig = {
          filters: [],
          organisationSummary: appData.display_config.summary,
          organisationDisplay: appData.display_config.profile,
          organisationFieldNames: {
            name: appData.display_config.name_fieldname,
            country: appData.display_config.country_fieldname,
            coordinates: appData.display_config.coords_fieldname,
            jeunes: appData.display_config.jeunes_fieldname,
            femmes: appData.display_config.femmes_fieldname,
            borderBase: appData.display_config.border_base_fieldname,
            registered: appData.display_config.registered_fieldname,
            size: appData.display_config.size_fieldname,
            budget: appData.display_config.budget_fieldname,
          },
        };

        const organisations = appData.data.map((item) =>
          API.parseOrganisationData(item, config)
        );

        const filters = API.extractFilterOptions(
          appData.display_config.filters,
          organisations
        );

        API.appData = {
          config: {
            ...config,
            filters,
          },
          organisations,
        };
      } catch (e: any) {
        // TODO: global error handling
        // eslint-disable-next-line no-console
        console.error(e.message);
      }
    }
    return API.appData;
  }

  static async loadConfig(refreshAppData = false): Promise<AppConfig> {
    const appData = await API.loadAppData(refreshAppData);
    return appData.config;
  }

  static async loadOrganisations(
    filters: FilterStates,
    refreshAppData = false
  ): Promise<Organisation[]> {
    // TODO: do the filtering on the server
    const {
      organisations,
      config: { filters: filterConfigs },
    } = await API.loadAppData(refreshAppData);

    // Filter the organisations
    // Organisations must match at least one value in each filter to be included
    return organisations.filter((org) => {
      // Don't show organisations with no data
      if (!org.data) {
        return false;
      }

      // Iterate through all the filters, AND-ing them together
      return filters.reduce<boolean>((result, filter) => {
        // Early exit if an earlier filter failed
        if (!result) {
          return false;
        }

        // If no options are specified, don't filter anything
        if (!filter.values.length) {
          return true;
        }

        // If the organisation doesn't have a matching field, don't filter it
        // This could happen with incorrect or out-of-date URLs
        if (typeof org.data[filter.fieldName] === "undefined") {
          return true;
        }

        const filterConfig = filterConfigs.find(
          (f) => f.fieldName === filter.fieldName
        );

        if (filterConfig?.type === "interval") {
          const orgValue = Number(org.data[filter.fieldName]);
          const { options } = filterConfig;
          // This works because the options are in descending order
          // See API.prepareOptions()
          const matchingOption = options.find(
            (o) => Number(o.value) < orgValue
          );
          const match =
            matchingOption && filter.values.includes(matchingOption.value);
          return match || false;
        }

        // Check that there is a matching value in the organisation and the filter
        const orgValues = org.data[filter.fieldName]
          .split(";")
          .map((s) => s.trim())
          .filter(Boolean);
        const intersect = orgValues.filter((v) => filter.values.includes(v));
        return intersect.length > 0;
      }, true);
    });
  }

  /**
   * Convert the raw data from the API to an Organisation.
   * Cleans some fields, and adds derived fields.
   *
   * At the moment, all the fields are strings, but this should
   * change if and when Organisation becomes properly defined and typed.
   *
   */
  static parseOrganisationData(
    data: {
      data_id: string;
      versions: { data: Dict }[];
    },
    config: AppConfig
  ): Organisation {
    const language = i18n.language === "en-GB" ? "en" : i18n.language;

    const dataInLanguage = data.versions.find((version) => {
      // @ts-expect-error Need to tighten typescript definitions here.
      return version.language === language;
    });

    const latestData = dataInLanguage?.data || {};
    const name = latestData[config.organisationFieldNames.name];

    const cleanData = Object.keys(latestData).reduce<Dict>(
      (clean, fieldName) => {
        return {
          ...clean,
          [fieldName]: API.cleanData(fieldName, latestData[fieldName], config),
        };
      },
      {}
    );

    cleanData[ORGANISATION_FOCUS_PROPERTY] = API.deriveOrganisationFocus(
      cleanData,
      config
    );

    return {
      id: data.data_id,
      name,
      slug: slugify(name),
      data: cleanData,
    };
  }

  static deriveOrganisationFocus(data: Dict, config: AppConfig): string {
    const focuses = [];
    const language = i18n.language === "en-GB" ? "en" : i18n.language;
    if (
      (data[config.organisationFieldNames.femmes] || "")
        .toLowerCase()
        .trim()
        .includes("oui") ||
      (data[config.organisationFieldNames.femmes] || "")
        .toLowerCase()
        .trim()
        .includes("yes")
    ) {
      const label = language === "fr" ? "Femmes" : "Women";
      focuses.push(label);
    }
    if (
      (data[config.organisationFieldNames.jeunes] || "")
        .toLowerCase()
        .trim()
        .includes("oui") ||
      (data[config.organisationFieldNames.jeunes] || "")
        .toLowerCase()
        .trim()
        .includes("yes")
    ) {
      const label = language === "fr" ? "Jeunes" : "Youth";
      focuses.push(label);
    }
    if ((data[config.organisationFieldNames.borderBase] || "").trim()) {
      const label =
        language === "fr" ? "Base transfrontalière" : "Cross-border";
      focuses.push(label);
    }
    return focuses.join(";");
  }

  static cleanData(fieldName: string, str: string, config: AppConfig): string {
    // Organisations encode multiple values with ';'
    const values = str.split(";");
    const cleanValues = values
      .map((value) => API.cleanValue(fieldName, value, config))
      .filter(Boolean);
    return cleanValues.join(";");
  }

  static cleanValue(
    fieldName: string,
    value: string,
    config: AppConfig
  ): string | number {
    let clean = value.trim().replace(/,$/, "");
    if (fieldName === config.organisationFieldNames.country) {
      clean = titleCase(clean.replace(/[\s-]+/, " "));
      if (["Burkirna Faso", "Burkina", "Burkina Fso"].includes(clean)) {
        clean = "Burkina Faso";
      }
    }
    if (fieldName === config.organisationFieldNames.budget) {
      const isEUR = clean.includes("EUR");
      const isUSD = clean.includes("USD");

      const USD_TO_FCFA = process.env.USD_TO_FCFA_RATE
        ? parseInt(process.env.USD_TO_FCFA_RATE, 10)
        : 565.66;

      const EUR_TO_FCFA = process.env.EUR_TO_FCFA_RATE
        ? parseInt(process.env.EUR_TO_FCFA_RATE, 10)
        : 656.92;

      // eslint-disable-next-line no-nested-ternary
      const scalar = isEUR ? EUR_TO_FCFA : isUSD ? USD_TO_FCFA : 1;
      const intVal = parseInt(clean.replace(/[^0-9]/g, ""), 10);
      return Number((intVal * scalar).toPrecision(3));
    }
    if (fieldName === config.organisationFieldNames.registered) {
      return ["oui", "yes"].includes(clean.toLowerCase()) ? TRUE_CONSTANT : FALSE_CONSTANT;
    }
    return clean;
  }

  // Combine the list of filters with the list of organisations to populate the filters with options
  static extractFilterOptions(
    filterConfigs: PartialFilterConfig[],
    organisations: Organisation[]
  ): FilterConfig[] {
    const filters = filterConfigs.map((f) => {
      return {
        ...f,
        options: API.prepareOptions(f),
      };
    });

    // For each organisation add its properties to the corresponding filter
    for (const org of organisations) {
      // Iterate over data properties
      for (const fieldName of Object.keys(org.data)) {
        const rawValue = org.data[fieldName].trim();

        // Find the matching filter
        const filter = filters.find((f) => f.fieldName === fieldName);

        if (rawValue && filter) {
          if (filter.type === "list") {
            const { options } = filter;

            // Organisations encode multiple values for a filter with ';'
            const allValues = rawValue.split(";");

            const uniqueValues = Object.keys(
              allValues.reduce((obj, v) => ({ ...obj, [v]: true }), {})
            );

            // For each value, add it to the filter as an option
            for (const value of uniqueValues) {
              let option = options.find((o) => o.value === value);

              if (!option) {
                option = { label: value, value, count: 0 };
                options.push(option);
              }

              option.count += 1;
            }
          } else if (filter.type === "interval") {
            const { options } = filter;

            const intVal = parseInt(rawValue, 10);

            if (intVal) {
              const matchingOption = options.find(
                (o) => Number(o.value) < intVal
              );
              if (matchingOption) {
                matchingOption.count += 1;
              }
            }
          }
        }
      }
    }

    return filters;
  }

  /**
   * Converts filter options from the API into Options objects.
   */
  static prepareOptions(filter: PartialFilterConfig): Option[] {
    if (filter.type === "interval") {
      const options = [];
      let last = null;

      filter.options.sort((a, b) => {
        return Number(b) - Number(a);
      });

      for (const option of filter.options) {
        const label =
          last === null
            ? `> ${option.toLocaleString()}`
            : `${option.toLocaleString()} - ${last.toLocaleString()}`;

        options.push({ label, value: String(option), count: 0 });
        last = option;
      }
      return options;
    }

    return (filter.options || []).map((o: string | number) => ({
      label: String(o),
      value: String(o),
      count: 0,
    }));
  }
}
