import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import "./index.css";
import "./i18n";

import App from "./components/app/App";
import AppConfigProvider from "./providers/AppConfigProvider";
import FilterStatesProvider from "./providers/FilterStatesProvider";
import OrganisationsProvider from "./providers/OrganisationsProvider";

/**
 * Provider purposes:
 *
 * - HelmetProvider: Manage <head> tags in components
 * - AppConfigProvider: Loads app config from the API on mount
 * - FilterStatesProvider: Records which filters are selected
 * - OrganisationsProvider: Updates the data when the filters change
 *
 */

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <AppConfigProvider>
        <FilterStatesProvider>
          <OrganisationsProvider>
            <App />
          </OrganisationsProvider>
        </FilterStatesProvider>
      </AppConfigProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
