import { useContext } from "react";
import FilterStatesContext from "../context/FilterStatesContext";

export default (
  fieldName: string
): [string[], (nextValues: string[]) => void] => {
  const { filterStates, setFilterState } = useContext(FilterStatesContext);

  const filterState = filterStates.find((item) => item.fieldName === fieldName);

  const values = filterState?.values || [];

  const setValues = (nextValues: string[]) => {
    setFilterState(fieldName, nextValues);
  };

  return [values, setValues];
};
