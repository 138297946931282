import React from "react";
import { useTranslation } from "react-i18next";

const MobileControls: React.FC<{
  filtersHidden: boolean;
  mapHidden: boolean;
  onToggleFilters: () => void;
  onToggleMap: () => void;
}> = ({ onToggleFilters, onToggleMap, filtersHidden, mapHidden }) => {
  const { t } = useTranslation<string>();
  return (
    <div className="flex md:hidden p-2">
      <button type="button" onClick={onToggleFilters} className="pill mr-auto">
        {t(filtersHidden ? "mobile.showFilters" : "mobile.hideFilters")}
      </button>
      <div>
        <button
          type="button"
          onClick={onToggleMap}
          className={`rounded-l-full px-3 py-1 border-2 border-r-0 ${
            mapHidden ? "bg-gray-200" : ""
          }`}
          disabled={mapHidden}
        >
          {t("mobile.list")}
        </button>
        <button
          type="button"
          onClick={onToggleMap}
          className={`rounded-r-full px-3 py-1 border-2 ${
            mapHidden ? "" : "bg-gray-200"
          }`}
          disabled={!mapHidden}
        >
          {t("mobile.map")}
        </button>
      </div>
    </div>
  );
};

export default MobileControls;
