import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Modal from "../modal/Modal";

const Reports: React.FC = () => {
  const { t } = useTranslation<string>();
  const history = useHistory();

  return (
    <Modal onClickOutside={() => history.push("/")}>
      <div className="py-4">
        <h2 className="text-2xl pb-6">{t("reports")}</h2>
        <hr className="pb-6" />
        <h2 className="text-xl pb-6">Burkina Faso</h2>
        <ul className="pb-4">
          <li><a className="underline cursor-pointer" target="blank" href={t("report_links.burkina_faso_centre_nord")}>Centre Nord</a></li>
          <li><a className="underline cursor-pointer" target="blank" href={t("report_links.burkina_faso_est")}>Est</a></li>
          <li><a className="underline cursor-pointer" target="blank" href={t("report_links.burkina_faso_nord")}>Nord</a></li>
          <li><a className="underline cursor-pointer" target="blank" href={t("report_links.burkina_faso_sahel")}>Sahel</a></li>
        </ul>
        <h2 className="text-xl pb-6">Mali</h2>
        <ul className="pb-4">
          <li><a className="underline cursor-pointer" target="blank" href={t("report_links.mali_gao")}>Gao</a></li>
          <li><a className="underline cursor-pointer" target="blank" href={t("report_links.mali_mopti")}>Mopti</a></li>
          <li><a className="underline cursor-pointer" target="blank" href={t("report_links.mali_segou")}>Segou</a></li>
          <li><a className="underline cursor-pointer" target="blank" href={t("report_links.mali_tombouctou")}>Tombouctou</a></li>
        </ul>
        <h2 className="text-xl pb-6">Niger</h2>
        <ul className="pb-4">
          <li><a className="underline cursor-pointer" target="blank" href={t("report_links.niger_diffa")}>Diffa</a></li>
          <li><a className="underline cursor-pointer" target="blank" href={t("report_links.niger_tillaberi")}>Tillaberi</a></li>
        </ul>
      </div>
    </Modal>
  );
};

export default Reports;
