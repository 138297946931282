/* eslint-disable @typescript-eslint/no-explicit-any */

import MapGL, {
  NavigationControl,
  Source,
  Layer,
  ScaleControl,
} from "@urbica/react-map-gl";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { MAPBOX_ACCESS_TOKEN, MAPBOX_STYLE } from "../../constants";
import AppConfigContext from "../../context/AppConfigContext";
import FilterStatesContext from "../../context/FilterStatesContext";
import OrganisationsContext from "../../context/OrganisationsContext";
import { Organisation } from "../../react-app-env";
import OrganisationMarker from "./OrganisationMarker";
import {
  calcBounds,
  convertOrganisationsToGeoJSON,
  extractLatLng,
  GeoJSON,
} from "./util";

const TRANSPARENT = "rgba(0,0,0,0)";
const TRANSLUCENT_BLUE = "rgba(54,26,198,0.5)";
const TRANSLUCENT_RED = "rgba(255,13,86,0.5)";
const OUTLINE_COLOR = "#E4E5EC";

const COLOR_SCALE = [TRANSPARENT, TRANSLUCENT_BLUE, TRANSLUCENT_RED];

const Map: React.FC<{
  autofocus: boolean;
  sidebarOpen: boolean;
  orgListOpen: boolean;
  setHoveredOrganisation: Dispatch<
    SetStateAction<Organisation | null | undefined>
  >;
  hoveredOrganisation: Organisation | null | undefined;
  viewMode: string;
}> = ({
  autofocus = false,
  sidebarOpen,
  orgListOpen,
  hoveredOrganisation,
  setHoveredOrganisation,
  viewMode,
}) => {
  const { config } = useContext(AppConfigContext);
  const {
    organisations,
    focusedOrganisation,
    setMap,
    map,
    mapRef,
  } = useContext(OrganisationsContext);
  const { setLocationFilter } = useContext(FilterStatesContext);
  const [viewport, setViewport] = useState({
    latitude: 14,
    longitude: 0,
    zoom: 3,
  });

  // Convert the organisations list into GeoJSON so it can be used as a heatmap source
  const orgGeoJSON = useMemo<GeoJSON>(() => {
    return convertOrganisationsToGeoJSON(
      organisations,
      config.organisationFieldNames.coordinates,
      config.organisationFieldNames.size
    );
  }, [
    config.organisationFieldNames.coordinates,
    config.organisationFieldNames.size,
    organisations,
  ]);

  // Call this function to update the global map bounds, used to filter the organisation list
  const updateLocationFilter = useCallback(() => {
    const bounds = (map as any).getBounds();
    const { lat: north, lng: west } = bounds.getNorthWest();
    const { lat: south, lng: east } = bounds.getSouthEast();
    setLocationFilter([
      [west, north],
      [east, south],
    ]);
  }, [map, setLocationFilter]);

  useEffect(() => {
    if (!mapRef) {
      return;
    }

    if (!mapRef.current) {
      return;
    }

    // @ts-expect-error Should error but will never happen.
    mapRef?.current?.getMap().resize();
  }, [sidebarOpen, orgListOpen, mapRef]);

  // Update the view when the list of organisations or focus changes
  useEffect(() => {
    if (!map) {
      return;
    }

    if (focusedOrganisation) {
      const latLng = focusedOrganisation
        ? extractLatLng(
            focusedOrganisation,
            config.organisationFieldNames.coordinates
          )
        : null;

      if (latLng && autofocus) {
        // Center map on focused organisation (desktop only)
        (map as any).easeTo({
          center: [latLng[1], latLng[0]],
          zoom: 10,
          maxDuration: 1000,
        });

        // Update the location filter when the ease is done as setViewport is not called automatically
        setTimeout(updateLocationFilter, 1000);
      }
    } else {
      // Zoom map to show all organisations
      const bounds = calcBounds(orgGeoJSON);
      if (bounds) {
        (map as any).fitBounds(bounds, { padding: 50 });
      }

      // clear location filter
      setLocationFilter(null);
    }
  }, [
    map,
    orgGeoJSON,
    focusedOrganisation,
    config.organisationFieldNames.coordinates,
    updateLocationFilter,
    setLocationFilter,
    autofocus,
  ]);

  const onViewportChange = (v: any) => {
    setViewport(v);
    updateLocationFilter();
  };

  const onClickCluster = (e: any) => {
    const m = map as any; // Assert not null
    const features = m.queryRenderedFeatures(e.point, {
      layers: ["cluster"],
    });
    const clusterId = features[0].properties.cluster_id;
    m.getSource("cluster").getClusterExpansionZoom(
      clusterId,
      (err: Error, zoom: number) => {
        if (err) return;

        m.easeTo({
          center: features[0].geometry.coordinates,
          zoom,
        });
      }
    );
  };

  const onLoad = (e: any) => {
    if (!map && e?.target) {
      setMap(e.target);
    }
  };

  const onHoverPoint = (e: any) => {
    const { features } = e;

    if (!features?.length) {
      setHoveredOrganisation(null);
      return;
    }

    const feature = features[0];

    const organisation = organisations.find(
      (org) => org.id === feature.properties.id
    );

    setHoveredOrganisation(organisation);
  };

  const onClickPoint = (e: any) => {
    onHoverPoint(e);
    (map as any).easeTo({ center: e.lngLat });
  };

  return (
    <>
      <MapGL
        style={{ flexGrow: 1, width: "100%", height: "100%" }}
        mapStyle={MAPBOX_STYLE}
        accessToken={MAPBOX_ACCESS_TOKEN}
        latitude={viewport.latitude}
        longitude={viewport.longitude}
        zoom={viewport.zoom}
        onLoad={onLoad}
        onViewportChange={onViewportChange}
        ref={mapRef}
      >
        <NavigationControl
          showCompass={false}
          showZoom
          position="bottom-right"
        />
        <Source id="organisations" type="geojson" data={orgGeoJSON} />
        <Source id="cluster" type="geojson" data={orgGeoJSON} cluster />
        {viewMode === "heatmap" ? (
          <Layer
            id="organisations"
            type="heatmap"
            source="organisations"
            paint={{
              // increase weight as organisation size increases
              "heatmap-weight": {
                property: "size",
                type: "exponential",
                stops: [
                  [1, 0],
                  [100, 1],
                  [10000, 2],
                  [100000, 3],
                ],
              },
              // // assign color values be applied to points depending on their density
              "heatmap-color": [
                "interpolate",
                ["linear"],
                ["heatmap-density"],
                0,
                COLOR_SCALE[0],
                0.1,
                COLOR_SCALE[1],
                1,
                COLOR_SCALE[2],
              ],
            }}
          />
        ) : (
          <>
            <Layer
              id="cluster"
              type="circle"
              source="cluster"
              maxzoom={6}
              filter={["has", "point_count"]}
              style={{ cursor: "pointer" }}
              onClick={onClickCluster}
              paint={{
                "circle-stroke-width": 2,
                "circle-stroke-color": OUTLINE_COLOR,
                "circle-opacity": 0.5,
                "circle-color": {
                  type: "interval",
                  property: "point_count",
                  stops: [
                    [2, COLOR_SCALE[1]],
                    [5, COLOR_SCALE[2]],
                  ],
                },
                "circle-radius": {
                  type: "exponential",
                  property: "point_count",
                  stops: [
                    [2, 20],
                    [50, 50],
                  ],
                },
              }}
            />
            <Layer
              id="cluster-count"
              type="symbol"
              source="cluster"
              maxzoom={6}
              layout={{
                "text-field": "{point_count_abbreviated}",
                "text-size": 12,
              }}
            />
            <Layer
              id="organisation-points"
              type="circle"
              source="organisations"
              minzoom={6}
              filter={["!", ["has", "point_count"]]}
              onClick={onClickPoint}
              onEnter={onHoverPoint}
              paint={{
                "circle-color": COLOR_SCALE[2],
                "circle-radius": 10,
                "circle-stroke-width": 2,
                "circle-stroke-color": OUTLINE_COLOR,
              }}
            />
          </>
        )}
        {hoveredOrganisation ? (
          <OrganisationMarker
            organisation={hoveredOrganisation}
            onHide={() => setHoveredOrganisation(null)}
          />
        ) : null}
        <ScaleControl maxWidth={100} unit="metric" position="bottom-left" />
        <div className="absolute bottom-10 left-32 w-20">
          <div className="inline-flex justify-between w-full">
            <p>1</p>
            <p>100</p>
          </div>
          <div className="h-2 bg-gradient-to-r from-heatblue to-heatred text-transparent">
            .
          </div>
        </div>
      </MapGL>
    </>
  );
};

export default Map;
