import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import LogoWithText from "../../assets/logo-text.svg";
import Modal from "../modal/Modal";

const About: React.FC = () => {
  const { t } = useTranslation<string>();
  const history = useHistory();

  return (
    <Modal onClickOutside={() => history.push("/")}>
      <div className="py-4">
        <h2 className="text-2xl pb-6">{t("about")}</h2>
        <hr className="pb-6" />
        <p className="pb-4">{t("about_part_one")}</p>
        <p className="pb-4">{t("about_part_two")}</p>
        <p className="pb-4">{t("about_part_three")}</p>
        <p className="pb-8">{t("about_part_four")}</p>
        <a
          href="https://www.un.org/peacebuilding/fund"
          target="_blank"
          rel="noreferrer"
        >
          <img src={LogoWithText} alt={t("United Nations Peacebuilding")} />
        </a>
      </div>
    </Modal>
  );
};

export default About;
