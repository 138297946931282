/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {marked} from "marked";

import { useParams, useHistory } from "react-router-dom";
import EmailIcon from "../../assets/email.svg";
import LinkIcon from "../../assets/link.svg";
import AppConfigContext from "../../context/AppConfigContext";
import OrganisationsContext from "../../context/OrganisationsContext";
import {
  getTranslation,
  extractEmailAddress,
  extractWebAddress,
} from "../../util";
import Modal from "../modal/Modal";
import { createProfileElement } from "../orglistitem/OrgListItem";

const getMarkdownText = (text: string, language: string) => {
  if (text) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const rawMarkup = marked.parse(text, { smartLists: true, breaks: true });
    return { __html: `<div>${rawMarkup}</div>` };
  }

  const noData =
    language === "fr" ? "<em>Pas de données</em>" : "<em>No data</em>";
  return { __html: noData };
};

const OrgView: React.FC<{
  currency: string;
}> = ({ currency }) => {
  const history = useHistory();
  const {
    i18n: { language },
    t,
  } = useTranslation<string>();
  const { slug } = useParams<{ slug: string }>();
  const {
    config: { organisationDisplay, organisationFieldNames },
  } = useContext(AppConfigContext);
  const { organisations, setFocusedOrganisation } = useContext(
    OrganisationsContext
  );

  const organisation = organisations.find((o) => o.slug === slug);

  useEffect(() => {
    if (organisation) {
      setFocusedOrganisation(organisation);
    }
  }, [organisation, setFocusedOrganisation]);

  if (!organisation) {
    return null;
  }

  const { data } = organisation;

  const email = extractEmailAddress(data);
  const webAddress = extractWebAddress(data);

  return (
    <Modal onClickOutside={() => history.push("/")}>
      <div>
        <h1 className="font-medium text-4xl pb-2 mt-2 mb-4">
          {data[organisationFieldNames.name]}
        </h1>
        <div className="pb-4 mb-2 border-b-2 flex">
          {email && (
            <a
              className="flex items-center justify-between pill mr-1"
              href={`mailto:${email}`}
            >
              <img
                alt=""
                className="inline mr-2"
                src={EmailIcon}
                style={{ width: 15 }}
              />
              {t("organisations.send_email")}
            </a>
          )}
          {webAddress && (
            <a
              className="flex items-center justify-between pill"
              href={webAddress}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt=""
                className="inline mr-2"
                src={LinkIcon}
                style={{ width: 15 }}
              />
              {t("organisations.visit_website")}
            </a>
          )}
        </div>
        {organisationDisplay.map((item, i) => (
          <div
            key={i}
            className={`pb-2 ${
              i === organisationDisplay.length - 1 ? "" : "border-b-2 mb-4"
            }`}
          >
            <h2 className="text-xl font-medium mb-2">
              {getTranslation(item.heading, language)}
            </h2>
            {item.body && (
              <div
                className="mb-2"
                dangerouslySetInnerHTML={
                  getMarkdownText(
                    data[item.body],
                    language
                  )}
              />
            )}
            {item.rows && (
              <div className="grid grid-cols-2">
                {item.rows.map((row) =>
                  row.map((col, j) => (
                    <div className="mb-2" key={j}>
                      {createProfileElement(data, col, language, currency)}
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default OrgView;
