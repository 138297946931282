import React, { useContext, useEffect, useRef, useState } from "react";
import AppConfigContext from "../context/AppConfigContext";
import FilterStatesContext from "../context/FilterStatesContext";
import OrganisationsContext from "../context/OrganisationsContext";
import { Organisation } from "../react-app-env";
import API from "../services/API";

const OrganisationsProvider: React.FC = ({ children }) => {
  const { ready } = useContext(AppConfigContext);
  const { filterStates } = useContext(FilterStatesContext);

  const [loading, setLoading] = useState(true);
  const [map, setMap] = useState(null);

  // focusedOrganisation handles centering the map on the organisation marker
  // it is set when OrgView is rendered, but not unset until the filters change
  // this is so that the map remains centered on the organisation when the user returns to the org list
  const [
    focusedOrganisation,
    setFocusedOrganisation,
  ] = useState<Organisation | null>(null);
  const [organisations, setOrganisations] = useState<Organisation[]>([]);

  // Reload the data from the API when the filters change
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      // Reset focused organisation when the organisations change
      setFocusedOrganisation(null);
      const orgs = await API.loadOrganisations(filterStates);
      setOrganisations(orgs);
      setLoading(false);
    };
    if (ready) {
      load();
    }
  }, [ready, filterStates]);

  const mapRef = useRef();

  return (
    <OrganisationsContext.Provider
      value={{
        organisations,
        loading,
        focusedOrganisation,
        setFocusedOrganisation,
        setMap,
        map,
        mapRef,
      }}
    >
      {children}
    </OrganisationsContext.Provider>
  );
};

export default OrganisationsProvider;
