import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const IntroText: React.FC = () => {
  const { t } = useTranslation<string>();

  return (
    <div className="flex py-2 px-4 flex-grow flex flex-col justify-between text-left text-sm border-b border-midgrey">
      <p className="pb-2">{t("introduction_text_part_one")}</p>
      <p className="py-2 font-medium">
        <Link
          className="underline cursor-pointer"
          role="link"
          to="/reports"
          tabIndex={0}
        >
          {t("introduction_text_part_three")}
        </Link>
      </p>
    </div>
  );
};

export default IntroText;
