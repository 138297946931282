import React, { useContext } from "react";
import AppConfigContext from "../../context/AppConfigContext";
import Filter from "../filter/Filter";

const Filters: React.FC = () => {
  const {
    config: { filters },
  } = useContext(AppConfigContext);

  return (
    <ul className="overflow-auto md:p-2">
      {filters.map((f) => (
        <li key={f.fieldName}>
          <Filter config={f} />
        </li>
      ))}
    </ul>
  );
};

export default Filters;
