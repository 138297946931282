/* eslint-disable react/no-array-index-key */
import classNames from "classnames";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { TRUE_CONSTANT } from "../../constants";
import AppConfigContext from "../../context/AppConfigContext";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";
import { Dict, DisplayItem, Organisation } from "../../react-app-env";
import { getTranslation } from "../../util";
import womenLedIcon from "../../assets/women-led.svg";
import youthLedIcon from "../../assets/youth-led.svg";
import registeredIcon from "../../assets/registered.svg";

export const createProfileElement = (
  properties: Dict,
  elementConfig: DisplayItem,
  language: string,
  currency: string
): JSX.Element => {
  let content;
  // console.log(properties)
  console.log(elementConfig)
  // console.log(language)
  // console.log(currency)
  // console.log('-----------------------------')

  const noData = <em>{language === "fr" ? "Pas de données" : "No data"}</em>;

  const FCFA_TO_USD_RATE = process.env.FCFA_TO_USD_RATE
    ? parseInt(process.env.FCFA_TO_USD_RATE, 10)
    : 0.0018;

  if (elementConfig.type === "field") {
    const key = elementConfig.content as string;

    if (elementConfig.contentType === "list") {
      const items = properties[key].split(";");
      const uniqueItems: string[] = [];

      for (let i = 0; i < items.length; i += 1) {
        const x = items[i];
        if (!uniqueItems.includes(x)) {
          uniqueItems.push(x);
        }
      }

      content = uniqueItems.length
        ? <ul className="list-outside list-disc">{uniqueItems.map((x) => <li key={x}>{x}</li>)}</ul>
        : noData;
    } else if (elementConfig.contentType === "boolean") {
      let icon;
      switch (key) {
        case "Female-led":
          icon = <img alt="female symbol" src={womenLedIcon} />;
          break;
        case "Youth-led":
          icon = <img alt="youth symbol" src={youthLedIcon} />;
          break;
        default:
          icon = <img alt="tick in ten pointed star" src={registeredIcon} />;
      }

      const display =
        properties[key] === TRUE_CONSTANT || properties[key] === "1";

      content = display ? (
        <span className="flex items-center text-gray-500 my-2">
          {icon}
          {getTranslation(elementConfig.label, language)}
        </span>
      ) : null;
    } else if (
      key === "Nombre de femmes salariées" ||
      key === "Nombre d'hommes salariés"
    ) {
      content = properties[key] ? Number(properties[key]).toFixed() : noData;
    } else if (
      key ===
        "Si oui, quel est votre budget annuel pour le dernier exercice financier ?" ||
      key === "Annual budget"
    ) {
      content = properties[key]
        ? `${(
            Number(properties[key]) *
            (currency === "USD" ? FCFA_TO_USD_RATE : 1)
          ).toLocaleString(language)} ${currency}`
        : noData;
    } else {
      content = properties[key] ? properties[key] : noData;
    }
  } else if (elementConfig.type === "sum") {
    const keys = (elementConfig.content as string).split(" + ");

    const total = keys.reduce<number>(
      (sum, key) => sum + parseInt(properties[key], 10),
      0
    );

    content = total || noData;
  } else {
    content = getTranslation(elementConfig.content, language);
  }

  switch (elementConfig.contentType) {
    case "label":
      return <span className="block text-gray-600">{content}</span>;
    case "heading":
      return <h2 className="mb-2 text-lg font-medium">{content}</h2>;
    default:
      return <span>{content}</span>;
  }
};

const createProfileTable = (
  properties: Dict,
  elementConfig: DisplayItem,
  language: string,
  currency: string
) => {
  return (
    <div
      className={`grid ${elementConfig.rows.length > 1 ? "grid-cols-2" : ""}`}
    >
      {elementConfig.rows.map((config) => {
        return config.map((item, i) => (
          <div key={i} className="mb-2">
            {createProfileElement(properties, item, language, currency)}
          </div>
        ));
      })}
    </div>
  );
};

const createProfileHtml = (
  properties: Dict,
  config: DisplayItem[],
  language: string,
  currency: string
) => {
  return (
    <>
      {config.map((c, i) => (
        <div key={i}>
          {c.rows
            ? createProfileTable(properties, c, language, currency)
            : createProfileElement(properties, c, language, currency)}
        </div>
      ))}
    </>
  );
};

const OrgListItem: React.FC<{
  org: Organisation;
  highlight: boolean;
  currency: string;
}> = ({ org, highlight, currency }) => {
  const appConfig = useContext(AppConfigContext);
  const language = useLanguage();
  const { t, i18n } = useTranslation<string>();

  const linkClasses = classNames(
    "org-list-item block p-4 border-2 mb-2 hover:bg-lighterblue",
    highlight === true ? "bg-lighterblue" : "bg-white"
  );

  return (
    <Link to={`/${org.slug}`} className={linkClasses}>
      {createProfileHtml(
        org.data,
        appConfig.config.organisationSummary,
        language,
        currency
      )}
      <button type="button" className="underline mt-4">
        {t("organisations.read_more")}
      </button>
    </Link>
  );
};

export default OrgListItem;
