import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as LeftPointingChevrons } from "../../assets/left-pointing-chevrons.svg";

const Navbar: React.FC<{
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ setSidebarOpen }) => {
  const { t } = useTranslation<string>();

  return (
    <header className="flex items-center bg-peaceblue">
      <h1 className="font-medium p-4 text-white flex-grow flex justify-between">
        <div>{t("title")}</div>
        <div>
          <button
            type="button"
            onClick={() => {
              setSidebarOpen(false);
            }}
          >
            <LeftPointingChevrons className="chevron hidden md:block" />
          </button>
        </div>
      </h1>
    </header>
  );
};

export default Navbar;
