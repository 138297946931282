import React, { useEffect, useState } from "react";
import AppConfigContext from "../context/AppConfigContext";
import i18n from "../i18n";
import { AppConfig } from "../react-app-env";
import API from "../services/API";

const AppConfigProvider: React.FC = ({ children }) => {
  const [ready, setReady] = useState(false);
  const [config, setConfig] = useState<AppConfig>({
    filters: [],
    organisationSummary: [],
    organisationDisplay: [],
    organisationFieldNames: {
      name: "",
      country: "",
      coordinates: "",
      registered: "",
      jeunes: "",
      femmes: "",
      borderBase: "",
      size: "",
      budget: "",
    },
  });

  // Load the filters from the API when this Provider is mounted
  useEffect(() => {
    const load = async () => {
      const loadedConfig = await API.loadConfig();
      setConfig(loadedConfig);
      setReady(true);
    };

    load();
  }, []);

  useEffect(() => {
    i18n.on("languageChanged", async () => {
      window.location.reload();
    });
  });

  return (
    <AppConfigContext.Provider value={{ ready, config, setReady, setConfig }}>
      {children}
    </AppConfigContext.Provider>
  );
};

export default AppConfigProvider;
