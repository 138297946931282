import React from "react";

const LoadingOverlay: React.FC = () => (
  <div className="fixed w-full h-full bg-gray-500 top-0 left-0 flex items-center justify-center flex-col">
    <div className="rounded-full h-24 w-24 border-t-2 animate-spin" />
    <div className="text-gray-400 mt-16 " id="progress" />
  </div>
);

export default LoadingOverlay;
