import React from "react";
import { useTranslation } from "react-i18next";

const Focuses: React.FC<{
  focuses: string[];
}> = ({ focuses }) => {
  const { t } = useTranslation<string>();

  return (
    <div className="flex mt-3">
      {focuses.map((focus) => (
        <div
          key={focus}
          className="bg-lightblue w-auto mr-1 px-2 rounded-sm truncate"
        >
          {t(focus)}
        </div>
      ))}
    </div>
  );
};

export default Focuses;
