import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { TRUE_CONSTANT } from "../../constants";
import useFilter from "../../hooks/useFilter";
import useLanguage from "../../hooks/useLanguage";
import { FilterConfig } from "../../react-app-env";
import { getTranslation } from "../../util";

const Filter: React.FC<{ config: FilterConfig }> = ({ config }) => {
  const [show, setShow] = useState(false);
  const [values, setValues] = useFilter(config.fieldName);

  const language = useLanguage();
  const label = getTranslation(config.labels, language);

  if (!config.options) {
    // TODO: implement other types of filter
    return null;
  }

  const onCheckOption = (value: string, checked: boolean): void => {
    if (checked && !values.includes(value)) {
      setValues([...values, value]);
    } else if (!checked && values.includes(value)) {
      setValues(values.filter((v) => v !== value));
    }
  };

  if (config.type === "boolean") {
    const on = values[0] === TRUE_CONSTANT;
    return (
      <div className="flex px-4 py-2 md:px-2 border-b-2 items-center">
        <h2 className="font-medium text-lg mr-auto">{label}</h2>
        <input
          type="checkbox"
          style={{ zoom: 1.5 }}
          onChange={() => onCheckOption(TRUE_CONSTANT, !on)}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col px-4 py-2 md:px-2 border-b-2">
      <button
        className={`text-left block ${show ? "mb-2" : ""}`}
        type="button"
        onClick={() => setShow(!show)}
      >
        <span className="flex items-center">
          <h2 className="font-medium text-lg mr-auto">{label}</h2>
          <FontAwesomeIcon icon={show ? faChevronUp : faChevronDown} />
        </span>
      </button>
      <ul className={`overflow-hidden ${show ? "" : "h-0"}`}>
        {config.options.map((o) => (
          <li key={o.value} className="flex items-center mb-1">
            <input
              id={`${label}_${o.label}`}
              className="mr-1"
              style={{ zoom: 1.5 }}
              type="checkbox"
              checked={values.includes(o.value)}
              onChange={(e) => onCheckOption(o.value, e.target.checked)}
            />
            <label htmlFor={`${label}_${o.label}`} className="mr-auto truncate">
              {getTranslation(o.label, language)}
            </label>
            <span className="text-gray-500">{o.count}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Filter;
