import { Bounds, Organisation } from "../../react-app-env";

export interface GeoJSON {
  type: string;
  features: {
    geometry: {
      coordinates: [number, number];
    };
  }[];
}

export const extractLatLng = (
  org: Organisation,
  coordinatesField: string
): number[] | null => {
  const rawCoordinates = org.data[coordinatesField] || "";
  const cleanCoordinates = rawCoordinates
    .trim()
    .split(/[ ,]+/)
    .map(Number)
    .filter((n) => n && n < 90 && n > -90);
  return cleanCoordinates.length === 2 ? cleanCoordinates : null;
};

export const calcBounds = (geoJSON: GeoJSON): Bounds | null => {
  let north = null;
  let west = null;
  let south = null;
  let east = null;
  // Create a bounding box of all the features
  for (const feature of geoJSON.features) {
    const { coordinates } = feature.geometry;

    if (!west || coordinates[0] < west) {
      [west] = coordinates;
    }

    if (!north || coordinates[1] > north) {
      [, north] = coordinates;
    }

    if (!east || coordinates[0] > east) {
      [east] = coordinates;
    }

    if (!south || coordinates[1] < south) {
      [, south] = coordinates;
    }
  }

  if (north && east && south && west) {
    return [
      [west, north],
      [east, south],
    ];
  }

  return null;
};

export const filterOrganisationsByCoordinates = (
  orgs: Organisation[],
  bounds: Bounds,
  organisationCoordinatesField: string
): Organisation[] => {
  return orgs.filter((org) => {
    const coordinates = extractLatLng(org, organisationCoordinatesField);
    if (!coordinates) {
      return false;
    }
    const [lat, lng] = coordinates;
    const [[west, north], [east, south]] = bounds;
    return lng > west && lng < east && lat < north && lat > south;
  });
};

export const convertOrganisationsToGeoJSON = (
  orgs: Organisation[],
  organisationCoordinatesField: string,
  organisationSizeField: string
): GeoJSON => {
  return {
    type: "FeatureCollection",
    features: orgs
      .map((org) => ({
        id: org.id,
        data: org.data,
        latLng: extractLatLng(org, organisationCoordinatesField),
      }))
      .filter((org) => org.latLng)
      .map((org) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: org.latLng ? [org.latLng[1], org.latLng[0]] : [0, 0],
        },
        properties: {
          id: org.id,
          size: parseInt(org.data[organisationSizeField], 10) || 1,
        },
      })),
  };
};
