import { Marker } from "@urbica/react-map-gl";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AppConfigContext from "../../context/AppConfigContext";
import { Organisation } from "../../react-app-env";
import { extractFocuses } from "../../util";
import Focuses from "../focuses/Focuses";
import { extractLatLng } from "./util";

const OrganisationMarker: React.FC<{
  organisation: Organisation;
  onHide: () => void;
}> = ({ organisation, onHide }) => {
  const { config } = useContext(AppConfigContext);
  const latLng = extractLatLng(
    organisation,
    config.organisationFieldNames.coordinates
  );

  if (!latLng) {
    return null;
  }

  const { data, slug } = organisation;

  const focuses = extractFocuses(data).slice(0, 3);

  return (
    <Marker latitude={latLng[0]} longitude={latLng[1]} anchor="bottom">
      <div
        style={{ marginBottom: "-2rem" }}
        className="pb-12"
        onMouseLeave={onHide}
      >
        <div className="max-w-sm bg-white p-2 rounded">
          <Link to={`/${slug}`} onClick={onHide}>
            <h2 className="font-medium text-lg">
              {data[config.organisationFieldNames.name]}
            </h2>
            {focuses.length > 0 && <Focuses focuses={focuses} />}
          </Link>
        </div>
      </div>
    </Marker>
  );
};

export default OrganisationMarker;
