import { createContext, MutableRefObject } from "react";
import { Organisation } from "../react-app-env";

interface OrganisationsContextType {
  loading: boolean;
  organisations: Organisation[]; // Display all organisations on the map for better UX (more coherent heatmap and points)
  focusedOrganisation: Organisation | null;
  setFocusedOrganisation: (org: Organisation | null) => void;
  map: null | boolean;
  setMap: any;
  mapRef: MutableRefObject<undefined>;
}

const OrganisationsContext = createContext<OrganisationsContextType>(
  {} as OrganisationsContextType
);

export default OrganisationsContext;
