import { createContext } from "react";
import { Bounds, FilterStates } from "../react-app-env";

interface FilterStatesContextType {
  filterStates: FilterStates;
  setFilterState: (id: string, values: string[]) => void;
  clearAllFilters: () => void;
  locationFilter: Bounds | null;
  setLocationFilter: (bounds: Bounds | null) => void;
}

/**
 * Context value can be found in FilterProvider
 */
const FilterStatesContext = createContext<FilterStatesContextType>(
  {} as FilterStatesContextType
);

export default FilterStatesContext;
