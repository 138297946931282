import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { DEFAULT_LANGUAGE } from "./constants";
import enTranslation from "./lang/en.json";
import frTranslation from "./lang/fr.json";

// the translations
const resources = {
  en: {
    translation: enTranslation,
  },
  fr: {
    translation: frTranslation,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: DEFAULT_LANGUAGE, // Default lang
    interpolation: {
      escapeValue: false, // react already handles xss
    },
    supportedLngs: ['fr', 'en'],
  });

export default i18n;
