export const API_URL = process.env.REACT_APP_API_URL;
export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
export const MAPBOX_STYLE = process.env.REACT_APP_MAPBOX_STYLE;

export const DEFAULT_LANGUAGE = "fr";

export const MOBILE_BREAKPOINT = 768;

export const ORGANISATION_FOCUS_PROPERTY = "Focus Spécifique"; // Derived property

// Convert boolean true back to 'Oui' for CSV export
export const TRUE_CONSTANT = "Oui";
export const FALSE_CONSTANT = "Non";